import { SetStateAction, useCallback, useState } from 'react';

const getStorageValue = (key: string, initialValue: any) => {
    if (typeof window === 'undefined') {
        return initialValue;
    }
    try {
        // Get from local storage by key
        const item = window.localStorage.getItem(key);
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
    } catch (error) {
        console.log(error);
        return initialValue;
    }
};

type useLocalStorageReturnType<T> = [T, (value: SetStateAction<T>, refreshPrevStorageValue?: boolean) => void, () => T];

export function useLocalStorage<T>(key: string, initialValue: T): useLocalStorageReturnType<T> {
    const [storedValue, setStoredValue] = useState<T>(() => getStorageValue(key, initialValue));

    const setValue: useLocalStorageReturnType<T>[1] = useCallback(
        (value, refreshPrevStorageValue) => {
            try {
                setStoredValue((_prevValue) => {
                    const prevValue = refreshPrevStorageValue ? getStorageValue(key, _prevValue) : _prevValue;

                    // Allow value to be a function so we have same API as useState
                    const valueToStore = value instanceof Function ? value(prevValue) : value;
                    // Save to local storage
                    if (typeof window !== 'undefined') {
                        window.localStorage.setItem(key, JSON.stringify(valueToStore));
                    }
                    // Save state
                    return valueToStore;
                });
            } catch (error) {
                // A more advanced implementation would handle the error case
                console.log(error);
            }
        },
        [key],
    );

    const getValue: () => useLocalStorageReturnType<T>[0] = useCallback(() => {
        return getStorageValue(key, storedValue);
        // it is necessary to have storedValue in the dependencies array
        // so the getValue will be updated when setValue called
    }, [key, storedValue]);

    return [storedValue, setValue, getValue];
}
