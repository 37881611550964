import { useContext, useEffect, useMemo, useState } from 'react';
import { queryClient } from '../../lib/react-query-client';
import { UserPreferences } from '../../types/userPreferences';
import { ApplicationType, AppTypeToPreferencesSavedItemsLid } from '../../utils/enums';
import { getSavedItemsByTypeConfig, updateSavedItemByIdConfig } from '../../utils/userSavedItem';
import { ProjectSpecificResourcesContext } from '../ProjectSpecificResourcesContext/Context';
import { SavedItemListResponseTypes } from '../Screener/types/savedScreens';

export const OnNewPreferencesSetEvent = 'PreferencesUpdater:onNewPreferencesSet';

export type PreferencesUpdaterProps = {
    applicationType?: ApplicationType;
    getPreferencesConfig?: { cacheTime?: number };
};

export function PreferencesUpdater({ applicationType, getPreferencesConfig }: PreferencesUpdaterProps) {
    if (!applicationType) {
        throw new Error('applicationType is required for PreferencesUpdater');
    }
    
    const [userPreferences, setUserPreferences] = useState<UserPreferences | undefined>(undefined);

    useEffect(() => {
        const eventListener = (event: any) => {
            setUserPreferences(event.detail.newValue);
        };

        window.addEventListener(OnNewPreferencesSetEvent as string, eventListener);

        return () => window.removeEventListener(OnNewPreferencesSetEvent as string, eventListener);
    }, []);

    const savedItemLid = useMemo(() => {
        const _savedItemLid = AppTypeToPreferencesSavedItemsLid[applicationType];

        if (_savedItemLid === undefined) {
            throw new Error(`No saved item lid found for application type ${applicationType}`);
        }

        return _savedItemLid;
    }, [applicationType]);

    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const getPreferencesQuery = sendSingleRequest<SavedItemListResponseTypes>(
        {
            types: [savedItemLid],
            includeValues: true,
            config: { cacheTime: getPreferencesConfig?.cacheTime || 3000 },
        },
        getSavedItemsByTypeConfig,
    );

    const existingUserPreferenceData = useMemo(() => {
        const data = getPreferencesQuery.data?.data;

        return data?.length !== undefined && data?.length > 0 ? data[0] : undefined;
    }, [getPreferencesQuery.data?.data]);

    const updatePreferencesQuery = sendSingleRequest<any>(
        {
            savedItems: existingUserPreferenceData?.id,
            config: {
                enabled: existingUserPreferenceData !== undefined && userPreferences !== undefined,
                cacheTime: 3000,
            },
            requestBody: { value: userPreferences },
        },
        {
            ...updateSavedItemByIdConfig,
            invalidateQuery: false,
        },
    );

    useEffect(() => {
        const value = updatePreferencesQuery?.data?.data?.value;

        if (!updatePreferencesQuery.isLoading && value && !updatePreferencesQuery.isSuccess) {
            setUserPreferences(undefined);
            queryClient.invalidateQueries('updateSavedItemById');
        }
    }, [updatePreferencesQuery]);

    return null;
}
