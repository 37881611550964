import { forwardRef, useImperativeHandle } from 'react';
import { useUserPreferences } from '../../hooks/useUserPreferences';

export type UserPreferencesProps = {
    useUserPreferencesProps: Parameters<typeof useUserPreferences>[0];
};

export type UserPreferencesRef = {
    setUserPreferences: ReturnType<typeof useUserPreferences>['setUserPreferences'];
};

export const UserPreferences = forwardRef<UserPreferencesRef, UserPreferencesProps>(
    ({ useUserPreferencesProps }, ref) => {
        const { setUserPreferences } = useUserPreferences(useUserPreferencesProps);

        useImperativeHandle(
            ref,
            () => ({
                // don't use getUserPreferences from here for regular get preferences process
                // this will not re-render if initial preferences didn't have enough time to load
                setUserPreferences,
            }),
            [setUserPreferences],
        );

        return null;
    },
);
