import { IServerSideGetRowsParams, SortModelItem } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import {
    IMetadataFields,
    ResultsKeys,
    ScreenerData,
    ScreenerEtfData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { queryClient } from '@cfra-nextgen-frontend/shared/src/lib/react-query-client';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';

export const getRowID = (params: any) => {
    // params.data should contain id, otherwise the grid will not work
    return params.data.id;
};

export const getSortFields = (sortModel: SortModelItem[], metadataFields: IMetadataFields[]) => {
    let querySort: SearchByParams = {};

    let getSortQueryField = (fieldName: string) =>
        metadataFields.filter((dict) => Object.keys(dict)[0] === fieldName)?.[0]?.[fieldName].source_field;

    if (sortModel.length > 0) {
        querySort = {
            sortDirection: sortModel.map((sort) => sort.sort).join(',') as 'asc' | 'desc' | undefined,
            orderBy: sortModel.map((sort) => getSortQueryField(sort.colId)).join(','),
        };
    }
    return querySort;
};

type GetScreenerDataSSRFunc = (props: SearchByParams) => Promise<ScreenerEtfData | ScreenerData>;

export type GetSsrDataExportFn = ReturnType<typeof determineGetSsrDataExportFn>;
export const determineGetSsrDataExportFn = (getScreenerDataSSR: GetScreenerDataSSRFunc, resultsKey?: ResultsKeys) =>
    function ({
        metadataFields,
        requestParams,
        _resultsKey,
        batchConfig,
    }: {
        metadataFields: IMetadataFields[];
        requestParams?: SearchByParams;
        _resultsKey?: ResultsKeys;
        batchConfig?: {
            total: number;
            chunkSize: number;
            maxConcurrentRequests: number;
            maxResultsWindow: number;
        };
    }) {
        if (!_resultsKey && !resultsKey) {
            throw new Error('resultsKey or _resultsKey is not provided in determineGetSsrDataExportFn.');
        }

        return async function (sortColumns: SortModelItem[]) {
            const baseParams: SearchByParams = {
                ...requestParams,
                ...getSortFields(sortColumns, metadataFields),
            };

            if (!batchConfig) {
                return getScreenerDataSSR(baseParams).then((response: any) => {
                    return response?.results?.[(_resultsKey || resultsKey) as ResultsKeys] || [];
                });
            }

            const {
                chunkSize,
                maxConcurrentRequests,
                maxResultsWindow,
            } = batchConfig;

            const total = batchConfig.total;
            const results: Array<ReturnType<typeof getScreenerDataSSR>> = [];

            const numChunks = Math.ceil(Math.min(total, maxResultsWindow) / chunkSize);

            const requests = [];
            for (let i = 0; i < numChunks; i++) {
                const from = i * chunkSize;
                const params = { ...baseParams, size: chunkSize, from };
                requests.push(() => getScreenerDataSSR(params));
            }

            for (let i = 0; i < requests.length; i += maxConcurrentRequests) {
                const chunk = requests.slice(i, i + maxConcurrentRequests);
                const responses = await Promise.all(chunk.map((req) => req()));
                responses.forEach((response) => {
                    // @ts-ignore
                    results.push(...(response?.results?.[(_resultsKey || resultsKey) as ResultsKeys] || []));
                });
            }

            if (total > maxResultsWindow) {
                // @ts-ignore
                let lastId = results[results.length - 1].id;
                while (results.length < total) {
                    const params: SearchByParams = { ...baseParams, size: chunkSize, searchAfter: lastId };
                    const response = await getScreenerDataSSR(params);
                    // @ts-ignore
                    const data = response?.results?.[(_resultsKey || resultsKey) as ResultsKeys] || [];
                    if (data.length === 0) {
                        break;
                    }

                    results.push(...data);
                    lastId = data[data.length - 1].id;
                }
            }

            return results;
        };
    };

export type GetDataSource = ReturnType<typeof determineGetDataSource>;

type DetermineGetDataSourceType = (
    getScreenerDataSSR: GetScreenerDataSSRFunc,
    resultsKey?: ResultsKeys,
) => (props: {
    metadataFields: IMetadataFields[];
    etfData: Array<{
        [key: string]: any;
    }>;
    requestParams?: SearchByParams;
    _resultsKey?: ResultsKeys;
    size?: number;
    defaultFrom?: number;
    queryKeyFirstElement?: string;
    useQueryClient?: boolean;
    extractSortParams?: boolean;
}) => {
    getRows(params: IServerSideGetRowsParams): Promise<void> | undefined;
};

export const determineGetDataSource: DetermineGetDataSourceType =
    (getScreenerDataSSR, resultsKey) =>
    ({
        metadataFields,
        etfData,
        requestParams,
        _resultsKey,
        size,
        defaultFrom,
        queryKeyFirstElement,
        useQueryClient,
        extractSortParams = true,
    }) => ({
        getRows(params: IServerSideGetRowsParams) {
            if (!_resultsKey && !resultsKey) {
                throw new Error('resultsKey or _resultsKey is not provided in determineGetDataSource.');
            }

            if (
                params.request.sortModel.length === 0 &&
                (!params.request.startRow || (params.request.startRow && params.request.startRow === 0))
            ) {
                params.success({ rowData: etfData });
            } else {
                // don't use spread operator here to not change the order of values in the queryKey
                const queryParams: SearchByParams = requestParams || {};
                queryParams.from = params.request.startRow || defaultFrom;
                queryParams.size = size || params.request.endRow;

                if (extractSortParams) {
                    const sortFields = getSortFields(params.request.sortModel, metadataFields);

                    queryParams.sortDirection = sortFields.sortDirection;
                    queryParams.orderBy = sortFields.orderBy;
                }

                // need to add queryParams.path in the end of the queryKey to make the queryKey be the same as queryKey from sendInfiniteRequest
                // and so to share query cache
                const queryKey = [queryKeyFirstElement, ...Object.values(queryParams), queryParams.path];

                const resultCallback = () => getScreenerDataSSR(queryParams);

                return (
                    useQueryClient ? queryClient.fetchQuery(queryKey, async () => resultCallback()) : resultCallback()
                ).then((response: any) => {
                    if (response) {
                        params.success({
                            rowData: response.results?.[(_resultsKey || resultsKey) as ResultsKeys] || [],
                        });
                    }
                });
            }
        },
    });
