import { Environments } from '@cfra-nextgen-frontend/shared/src/utils/enums';

export const MSA_MICROSERVICES_URL = process.env.REACT_APP_MSA_MICROSERVICES;
export const MSA_ATHENA_URL = process.env.REACT_APP_MSA_ATHENA;
export const OPENSEARCH_URL = process.env.REACT_APP_OPENSEARCH_URL as string;
export const USER_MANAGEMENT_URL = process.env.REACT_APP_USER_MANAGEMENT_URL as string;
export const ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_URL as string;
export const API_URL =
    (process.env.REACT_APP_API_URL as string) +
        (process.env.REACT_APP_API_BACKEND_ENVIRONMENT_PATH_OVERRIDE ||
        process.env.REACT_APP_API_BACKEND_ENVIRONMENT_PATH_OVERRIDE === ''
            ? process.env.REACT_APP_API_BACKEND_ENVIRONMENT_PATH_OVERRIDE
            : process.env.REACT_APP_API_BACKEND_ENVIRONMENT_PATH) || '';
export const BASE_API_URL = process.env.REACT_APP_API_URL as string;
export const COGNITO_REFRESH_TOKEN_DELTA = Number(process.env.REACT_APP_COGNITO_REFRESH_TOKEN_DELTA);
export const COGNITO_ENVIRONMENT = process.env.REACT_APP_COGNITO_ENVIRONMENT as string;
export const ENVIRONMENT = process.env.NODE_ENV;
export const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME;
export const IS_STRICT_MODE = ENVIRONMENT_NAME !== Environments.Production && ENVIRONMENT_NAME !== Environments.UAT;
export const MSA_API_URL = process.env.REACT_APP_MSA_API_URL as string;
export const ENABLE_MSW = (process.env.REACT_APP_ENABLE_MSW === 'true') as boolean;
export const ENABLE_RUM = (process.env.REACT_APP_ENABLE_RUM === 'true') as boolean;
export const ENABLE_ETF_SCREENER = (process.env.REACT_APP_ENABLE_ETF_SCREENER === 'true') as boolean;
export const ENABLE_CFRA_INSIGHTS = (process.env.REACT_APP_ENABLE_CFRA_INSIGHTS === 'true') as boolean;
export const RUM_GUEST_ROLE_ARN = process.env.REACT_APP_RUM_GUEST_ROLE_ARN as string;
export const RUM_IDENTITY_POOL_ID = process.env.REACT_APP_RUM_IDENTITY_POOL_ID as string;
export const RUM_ENDPOINT = process.env.REACT_APP_RUM_ENDPOINT as string;
export const RUM_APPLICATION_ID = process.env.REACT_APP_RUM_APPLICATION_ID as string;
export const RUM_APPLICATION_REGION = process.env.REACT_APP_RUM_APPLICATION_REGION as string;
export const AG_GRID_ENT_LICENSE = process.env.REACT_APP_AG_GRID_ENT_LICENSE as string;
export const MUI_X_PRO_LICENSE = process.env.REACT_APP_MUI_X_PRO_LICENSE as string;
export const AUTH_REGION = process.env.REACT_APP_AUTH_REGION as string;
export const AUTH_USER_POOL_ID = process.env.REACT_APP_AUTH_USER_POOL_ID as string;
export const AUTH_USER_POOL_WEB_CLIENT_ID = process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID as string;
export const JIRA_ISSUE_COLLECTOR_URL = process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_URL as string;
export const HIDE_HAZARD_LIST = (process.env.REACT_APP_HIDE_HAZARD_LIST === 'true') as boolean;