import {
    ExtractFnReturnType,
    QueryConfig,
    queryClient,
} from '@cfra-nextgen-frontend/shared/src/lib/react-query-client';
import {
    ApiInstanceTypes,
    ApiNames,
    DateRanges,
    Frequencies,
    RequestTypes,
    UsageRequestTypes,
} from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { API } from 'aws-amplify';
import { AxiosInstance } from 'axios';
import moment from 'moment';
import { QueryKey, UseQueryOptions, UseQueryResult, useQueries, useQuery } from 'react-query';

export type SearchByParams = {
    aggregateBy?: string;
    alertId?: string;
    cfraId?: string;
    cfraIds?: Array<string>;
    config?: QueryConfig<QueryFnType>;
    countField?: string;
    dateRange?: DateRanges;
    doFreshRequest?: boolean;
    elapsedTimeHrs?: number;
    endDate?: string;
    entityTypeLid?: number | string;
    exchange?: string;
    fields?: Array<string>;
    filterBy?: Array<{ fieldName: string; value: string }>;
    filters?: string;
    frequency?: string;
    from?: number;
    groupBy?: Array<string>;
    id?: string;
    includeData?: boolean;
    includeMetadata?: boolean;
    includeValues?: boolean;
    isDistinct?: boolean;
    noErrorOnNoKeyValuePairs?: boolean;
    numberOfYears?: number;
    orderBy?: string;
    overrideRequestPath?: string;
    pageLid?: number;
    path?: string;
    processResponse?: (data: UseQueryResult<any>) => UseQueryResult<any>;
    productLid?: number | string;
    q?: string;
    requestBody?: any;
    researchReportTypeId?: string;
    savedItems?: number | Array<number>;
    search?: string;
    searchAfter?: string;
    securityType?: string;
    size?: number;
    sortDirection?: 'asc' | 'desc';
    startDate?: string;
    thematicScreen?: string;
    ticker?: string;
    tickerExchanges?: Array<{ ticker: string; exchange: string }>;
    top?: number;
    type?: string;
    types?: Array<number>;
    usageTypeLid?: number | string;
    usePrefetchQuery?: boolean;
    userEmail?: string;
    version?: string;
    view?: string;
    viewMode?: string;
};

export type QueryFnType = ReturnType<typeof determineGetData>;

type GetDataParams = {
    requestQuery: string;
    requestType: RequestTypes;
    apiName: ApiNames;
    requestBody?: any;
};

export type UseDataOptions = {
    queryKey: QueryKey;
    config?: QueryConfig<QueryFnType>;
} & GetDataParams;

type ApiDetails = {
    instance: AxiosInstance | typeof API;
    instanceType: ApiInstanceTypes;
};

function getUseQueryOptions<T>(props: UseDataOptions, getData: QueryFnType): UseQueryOptions<T> {
    let { requestQuery, queryKey, config, requestBody, requestType, apiName } = props;

    requestQuery = getFullRequestQuery({ apiName, requestQuery });

    return {
        queryKey: queryKey,
        queryFn: () => getData<T>({ requestQuery, requestType, apiName, requestBody }),
        notifyOnChangeProps: 'tracked',
        ...config,
    } as UseQueryOptions<T>;
}

export const determineUseMultipleData = (getData: QueryFnType) =>
    function <T>(props: Array<UseDataOptions>): Array<UseQueryResult<T>> {
        return useQueries<Array<ExtractFnReturnType<QueryFnType>>>(
            props.map((props) => getUseQueryOptions<T>(props, getData)),
        ) as Array<UseQueryResult<T>>;
    };

export type UseDataType = ReturnType<typeof determineUseData>;
export type UseMultipleDataType = ReturnType<typeof determineUseMultipleData>;

export const determineUseData = (getData: QueryFnType) =>
    function <T>(props: UseDataOptions): UseQueryResult<T> {
        return useQuery<ExtractFnReturnType<QueryFnType>>(
            getUseQueryOptions<T>(props, getData) as UseQueryOptions,
        ) as UseQueryResult<T>;
    };

export const determinePrefetchQuery = (getData: QueryFnType) =>
    function <T>({ apiName, requestType, requestQuery, queryKey, requestBody }: UseDataOptions) {
        requestQuery = getFullRequestQuery({ apiName, requestQuery });
        return queryClient.prefetchQuery({
            queryKey,
            queryFn: () => getData<T>({ requestQuery, requestType: requestType!, apiName: apiName!, requestBody }),
        });
    };

export async function invalidateQueriesByKey(queryKey: any) {
    await queryClient.invalidateQueries({ queryKey });
}

export type Error = {
    response?: Response & { data?: any };
};

const defaultApiDataGetter = (responseData: any) => responseData?.data;

export function determineGetData({
    apiNameToApiDetails,
    useDataGetter = defaultApiDataGetter,
}: {
    apiNameToApiDetails: Partial<Record<ApiNames, ApiDetails>>;
    useDataGetter?: (responseData: any) => any;
}) {
    return async function <T>({ requestQuery, requestType, apiName, requestBody }: GetDataParams): Promise<T> {
        if (!apiNameToApiDetails.hasOwnProperty(apiName)) {
            throw new Error(`getData received unexpected API name - "${apiName}"`);
        }

        if (!apiNameToApiDetails[apiName] || !apiNameToApiDetails[apiName]!.instance) {
            throw new Error(`getData received invalid axios instance for "${apiName}" api name`);
        }

        function sendRequest(apiInstance: ApiDetails) {
            switch (apiInstance.instanceType) {
                case ApiInstanceTypes.Axios:
                    switch (requestType) {
                        case RequestTypes.DELETE:
                            return (apiInstance.instance as AxiosInstance).delete(requestQuery);
                        case RequestTypes.GET:
                            return (apiInstance.instance as AxiosInstance).get(requestQuery);
                        case RequestTypes.POST:
                            return (apiInstance.instance as AxiosInstance).post(requestQuery, requestBody);
                        case RequestTypes.PUT:
                            return (apiInstance.instance as AxiosInstance).put(requestQuery, requestBody);
                        default:
                            throw new Error(
                                `determineGetData received unexpected request type for ${apiName} api - "${requestType}"`,
                            );
                    }
                case ApiInstanceTypes.AwsAmplify:
                    switch (requestType) {
                        case RequestTypes.DELETE:
                            return (apiInstance.instance as typeof API).del(apiName, requestQuery, {});
                        case RequestTypes.GET:
                            return (apiInstance.instance as typeof API).get(apiName, requestQuery, {});
                        case RequestTypes.POST:
                            return (apiInstance.instance as typeof API).post(apiName, requestQuery, {
                                body: requestBody,
                            });
                        case RequestTypes.PUT:
                            return (apiInstance.instance as typeof API).put(apiName, requestQuery, {
                                body: requestBody,
                            });
                        default:
                            throw new Error(
                                `determineGetData received unexpected request type for ${apiName} api - "${requestType}"`,
                            );
                    }
                default:
                    throw new Error(
                        `determineGetData received unexpected request type for ${apiName} api - "${requestType}"`,
                    );
            }
        }

        try {
            const response = await sendRequest(apiNameToApiDetails[apiName]!);
            return useDataGetter(response);
        } catch (error) {
            switch (apiNameToApiDetails[apiName]?.instanceType) {
                case ApiInstanceTypes.AwsAmplify:
                    const _error = error as Error;
                    let errorData = _error?.response?.data;

                    if (errorData) {
                        errorData = { ...errorData, isErroredResponse: true };
                    }

                    return new Promise<T>((resolve, reject) => {
                        resolve((errorData || undefined) as T);
                    });
                default:
                    console.error(requestQuery, error);
                    return new Promise<T>((resolve, reject) => {
                        resolve(undefined as T);
                    });
            }
        }
    };
}

export function getFullRequestQuery({ apiName, requestQuery }: { apiName: ApiNames; requestQuery: string }) {
    switch (apiName) {
        case ApiNames.Fundynamix:
            return `/etf-insights/etf-data/${requestQuery}`;
        case ApiNames.OpenSearch:
            return `/search/v1/${requestQuery}`;
        case ApiNames.UserManagement:
        case ApiNames.UserManagementUnauthenticated:
            return `/user/v1/${requestQuery}`;
        case ApiNames.PlatformManagementInternal:
            return `/current/user/v1/${requestQuery}`;
        case ApiNames.Research:
            return `search/v1/${requestQuery}`;
        case ApiNames.AI:
            return `/current/${requestQuery}`;
        default:
            throw new Error(`getFullRequestQuery received unexpected api name - "${apiName}"`);
    }
}

export function getFrequency(dateRange: DateRanges): Frequencies {
    if ([DateRanges.OneWeek, DateRanges.OneMonth].includes(dateRange)) return Frequencies.Daily;
    if ([DateRanges.ThreeMonth, DateRanges.SixMonth, DateRanges.OneYear].includes(dateRange)) return Frequencies.Weekly;
    return Frequencies.Monthly;
}

function getDateRangeParameters(dateRange: DateRanges): string {
    const getDateString = (date: Date, dateFormat: string = 'YYYY-MM-DD') => moment.utc(date).format(dateFormat); // format date from Date type to string with format YYYY-MM-DD
    const todaysDate = new Date();
    todaysDate.setDate(todaysDate.getDate() - 1); // change to yesterdays date
    const endDate = getDateString(todaysDate);
    const frequency = getFrequency(dateRange);

    if ([DateRanges.OneWeek, DateRanges.OneMonth].includes(dateRange)) {
        todaysDate.setMonth(todaysDate.getMonth() - 1); // get 1 month before yesterday date
        const startDate = getDateString(todaysDate);
        const top = 5;
        return dateRange === DateRanges.OneWeek
            ? getKeyValuePairs({ endDate, top, frequency })
            : getKeyValuePairs({ startDate, endDate, frequency });
    }
    if ([DateRanges.ThreeMonth, DateRanges.SixMonth].includes(dateRange)) {
        let numberOfMonthBefore = dateRange === DateRanges.ThreeMonth ? 3 : 6;
        todaysDate.setMonth(todaysDate.getMonth() - numberOfMonthBefore); // get 3 or 6 month before yesterday date
        const startDate = getDateString(todaysDate);
        return getKeyValuePairs({ startDate, endDate, frequency });
    }
    if ([DateRanges.OneYear, DateRanges.ThreeYears, DateRanges.FiveYears].includes(dateRange)) {
        let numberOfYearsBefore = dateRange === DateRanges.OneYear ? 1 : dateRange === DateRanges.ThreeYears ? 3 : 5;
        todaysDate.setFullYear(todaysDate.getFullYear() - numberOfYearsBefore); // get 1 or 3 or 5 year before yesterday date
        const startDate = getDateString(todaysDate);
        return getKeyValuePairs({ startDate, endDate, frequency });
    }
    throw new Error('Invalid dateRange specified');
}

function getKeyValuePairs(params: SearchByParams): string {
    const {
        aggregateBy,
        alertId,
        cfraIds,
        countField,
        dateRange,
        elapsedTimeHrs,
        endDate,
        entityTypeLid,
        fields,
        filterBy,
        filters,
        frequency,
        from,
        groupBy,
        includeData,
        includeMetadata,
        includeValues,
        isDistinct,
        numberOfYears,
        orderBy,
        productLid,
        q,
        researchReportTypeId,
        search,
        searchAfter,
        securityType,
        size,
        sortDirection,
        startDate,
        thematicScreen,
        tickerExchanges,
        top,
        type,
        types,
        usageTypeLid,
        userEmail,
        version,
        view,
        viewMode,
    } = params;
    let keyValuePairsQuery: string = '';
    const setKeyValuePair = (keyValuePair: string): void => {
        keyValuePairsQuery = Boolean(keyValuePairsQuery) ? `${keyValuePairsQuery}&${keyValuePair}` : keyValuePair;
    };

    if (top || top === 0) setKeyValuePair(`top=${top}`);
    if (numberOfYears) setKeyValuePair(`number_of_years=${numberOfYears}`);
    if (dateRange) setKeyValuePair(getDateRangeParameters(dateRange));
    if (startDate) setKeyValuePair(`start_date=${startDate}`);
    if (endDate) setKeyValuePair(`end_date=${endDate}`);
    if (frequency) setKeyValuePair(`frequency=${frequency}`);
    if (from || from === 0) setKeyValuePair(`from=${from}`);
    if (cfraIds) setKeyValuePair(`cfra_ids=${cfraIds.join(',')}`);
    if (tickerExchanges)
        setKeyValuePair(
            `ticker-exchanges=${tickerExchanges.map((element) => `${element.ticker}-${element.exchange}`).join(',')}`,
        );
    if (orderBy) setKeyValuePair(`order_by=${orderBy}`);
    if (sortDirection) setKeyValuePair(`sort_direction=${sortDirection}`);
    if (filterBy)
        setKeyValuePair(`filter_by=${filterBy.map((element) => `${element.fieldName}:${element.value}`).join(',')}`);
    if (fields) setKeyValuePair(`fields=${fields.join(',')}`);
    if (aggregateBy) setKeyValuePair(`aggregate_by=${aggregateBy}`);
    if (securityType) setKeyValuePair(`security_type=${securityType}`);
    if (size || size === 0) setKeyValuePair(`size=${size}`);
    if (view) setKeyValuePair(`view=${view}`);
    if (search) setKeyValuePair(`search=${search}`);
    if (filters) setKeyValuePair(`filters=${filters}`);

    if (typeof includeData === 'boolean') {
        if (includeData) {
            setKeyValuePair(`include_data=1`);
        } else {
            setKeyValuePair(`include_data=0`);
        }
    }
    if (typeof includeMetadata === 'boolean') {
        if (includeMetadata) {
            setKeyValuePair(`include_metadata=1`);
        } else {
            setKeyValuePair(`include_metadata=0`);
        }
    }
    if (Array.isArray(types) && types.length > 0) setKeyValuePair(`types=${types.join(',')}`);
    if (type) setKeyValuePair(`type=${type}`);
    if (includeValues) setKeyValuePair(`include_values=${includeValues}`);
    if (thematicScreen) setKeyValuePair(`thematic_screen=${thematicScreen}`);
    if (q) setKeyValuePair(`q=${q}`);
    if (viewMode) setKeyValuePair(`view_mode=${viewMode}`);
    if (elapsedTimeHrs) setKeyValuePair(`elapsed_time_hrs=${elapsedTimeHrs}`);
    if (researchReportTypeId) setKeyValuePair(`research_report_type_id=${researchReportTypeId}`);
    if (productLid) setKeyValuePair(`product_lid=${productLid}`);
    if (usageTypeLid) setKeyValuePair(`usage_type_lid=${usageTypeLid}`);
    if (entityTypeLid) setKeyValuePair(`entity_type_lid=${entityTypeLid}`);
    if (version) setKeyValuePair(`version=${version}`);
    if (countField) setKeyValuePair(`count_field=${countField}`);
    if (groupBy) setKeyValuePair(`group_by=${groupBy.join(',')}`);
    if (userEmail) setKeyValuePair(`user_email=${userEmail}`);
    if (alertId) setKeyValuePair(`alert_id=${alertId}`);
    if (isDistinct) setKeyValuePair(`isDistinct=${isDistinct}`);
    if (searchAfter) setKeyValuePair(`search_after=${searchAfter}`);

    return keyValuePairsQuery;
}

export function getRequestQuery(params: SearchByParams, path?: string): string {
    const { cfraId, ticker, exchange, cfraIds, tickerExchanges, savedItems } = params;

    const keyValuePairsQuery = getKeyValuePairs(params);

    let requestQuery = path ? path : '';
    if (cfraId) {
        requestQuery += `cfra_id/${cfraId}`;
    } else if (ticker && exchange) {
        requestQuery += `ticker/${ticker}/exchange/${exchange}`;
    } else if (cfraIds) {
        requestQuery += `cfra_ids`;
    } else if (tickerExchanges) {
        requestQuery += `ticker-exchanges`;
    } else if (savedItems || savedItems === 0) {
        requestQuery += `saved-items/${savedItems}`;
    } else if (keyValuePairsQuery === '' && params.config?.enabled !== false && !params.noErrorOnNoKeyValuePairs) {
        throw new Error('Parameters were not specified');
    }

    return Boolean(keyValuePairsQuery) ? `${requestQuery}?${keyValuePairsQuery}` : requestQuery;
}

export function getFiltersReqBody(filtersData: Record<string, any> | undefined) {
    return filtersData ? { ...(filtersData && { filters: { values: filtersData } }) } : undefined;
}

export function getUsageApiQueryKey(type: UsageRequestTypes, additionalQueryKey?: string) {
    return `${additionalQueryKey || ''}${type}_usage_query`;
}
