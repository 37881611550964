import { AnalyticsDataContext } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import { forwardRef, useCallback, useContext, useImperativeHandle } from 'react';

export type AnalyticsDataPickerRefValue = {
    registerAction: NonNullable<typeof globalThis.analytics.registerAction>;
};

type AnalyticsDataPickerProps = {
    ignoreIfNoAnalyticsDataContext?: boolean;
};

export const AnalyticsDataPicker = forwardRef<AnalyticsDataPickerRefValue, AnalyticsDataPickerProps>(
    ({ ignoreIfNoAnalyticsDataContext = true }, ref) => {
        const analyticsDataContext = useContext(AnalyticsDataContext);
        const { cfraDataLocal } = analyticsDataContext || {};

        const registerAction: AnalyticsDataPickerRefValue['registerAction'] = useCallback(
            (actionData) => {
                if (ignoreIfNoAnalyticsDataContext && !analyticsDataContext) {
                    return;
                }

                globalThis.analytics?.registerAction?.({
                    ...cfraDataLocal?.actionData,
                    ...actionData,
                });
            },
            [cfraDataLocal, ignoreIfNoAnalyticsDataContext, analyticsDataContext],
        );

        useImperativeHandle(ref, () => ({
            registerAction,
        }));

        return null;
    },
);
