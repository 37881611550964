import { createContext, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { UserPreferences } from '../../types/userPreferences';
import { PreferencesFetcher, PreferencesFetcherProps } from './PreferencesFetcher';
import { PreferencesUpdater } from './PreferencesUpdater';

export type UserInitialPreferencesContextProps = {
    userInitialPreferences?: UserPreferences;
};

export const UserInitialPreferencesContext = createContext<UserInitialPreferencesContextProps>(
    {} as UserInitialPreferencesContextProps,
);

export type UserInitialPreferencesContextProviderProps = {
    preferencesFetcherProps: Omit<PreferencesFetcherProps, 'onSuccess'>;
    children: ReactNode;
};

export const getPreferencesStorageKey = (applicationType?: string) =>
    applicationType ? `${applicationType}_preferences` : 'cfra-local-storage-placeholder';

export function UserInitialPreferencesContextProvider({
    children,
    preferencesFetcherProps,
}: UserInitialPreferencesContextProviderProps) {
    const preferencesStorageProps = useMemo(
        () => [getPreferencesStorageKey(preferencesFetcherProps.applicationType), {} as UserPreferences] as const,
        [preferencesFetcherProps.applicationType],
    );

    const [userInitialPreferences, setUserInitialPreferences] = useLocalStorage<UserPreferences>(
        ...preferencesStorageProps,
    );
    const memorizedChildren = useMemo(() => children, [children]);

    const onSuccessFetchPreferences = useCallback(
        (userInitialPreferences: UserPreferences) => {
            setUserInitialPreferences((userPrevInitialPreferences) => {
                if (JSON.stringify(userPrevInitialPreferences) !== JSON.stringify(userInitialPreferences)) {
                    return userInitialPreferences;
                }

                return userPrevInitialPreferences;
            });
        },
        [setUserInitialPreferences],
    );

    useEffect(() => {
        return () => {
            // clean preferences on unmount
            window?.localStorage?.removeItem(preferencesStorageProps[0]);
        };
    }, [preferencesStorageProps]);

    const preferencesFetcher = useMemo(
        () => <PreferencesFetcher {...preferencesFetcherProps} onSuccess={onSuccessFetchPreferences} />,
        [preferencesFetcherProps, onSuccessFetchPreferences],
    );

    const preferencesUpdater = useMemo(
        () => <PreferencesUpdater {...preferencesFetcherProps} />,
        [preferencesFetcherProps],
    );

    return (
        <>
            <UserInitialPreferencesContext.Provider value={{ userInitialPreferences }}>
                {memorizedChildren}
            </UserInitialPreferencesContext.Provider>
            {preferencesFetcher}
            {preferencesUpdater}
        </>
    );
}
