import {
    createContext,
    Dispatch,
    RefObject,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

export type CfraDataLocal = Omit<typeof globalThis.cfraData, 'userData' | 'siteData' | 'pageData'>;

type SetCfraDataLocal = (
    value: SetStateAction<CfraDataLocal | undefined>,
    shouldPropagate?: (props: {
        cfraDataLocalState?: CfraDataLocal;
        cfraDataLocalInputProp?: CfraDataLocal;
        cfraDataLocalFromContext?: CfraDataLocal;
    }) => boolean,
    lockInsidePage?: boolean,
) => void;

type AnalyticsDataContextProps = {
    cfraDataLocal?: CfraDataLocal;
    setCfraDataLocalRef?: RefObject<SetCfraDataLocal>;
};

export const AnalyticsDataContext = createContext<AnalyticsDataContextProps>({} as AnalyticsDataContextProps);

export function AnalyticsDataContextProvider({
    cfraDataLocal: _cfraDataLocalInputProp,
    children,
    isPageLevelContext,
}: {
    cfraDataLocal?: CfraDataLocal;
    children: React.ReactNode;
    isPageLevelContext?: boolean;
}) {
    const memorizedChildren = useMemo(() => children, [children]);

    const analyticsDataContext = useContext(AnalyticsDataContext);
    const { cfraDataLocal: cfraDataLocalFromContext, setCfraDataLocalRef: setCfraDataLocalFromContextRef } =
        analyticsDataContext || {};

    const [cfraDataLocalInputProp, setCfraDataLocalInputProp] = useState(_cfraDataLocalInputProp);

    useEffect(() => {
        setCfraDataLocalInputProp((previousValue) => {
            if (JSON.stringify(previousValue) !== JSON.stringify(_cfraDataLocalInputProp)) {
                return _cfraDataLocalInputProp;
            }

            return previousValue;
        });
    }, [_cfraDataLocalInputProp]);

    const [cfraDataLocalState, _setCfraDataLocalState] = useState(cfraDataLocalInputProp);

    const setCfraDataLocalStateOptimized: Dispatch<SetStateAction<CfraDataLocal | undefined>> = useCallback((value) => {
        _setCfraDataLocalState((previousValue) => {
            const newValue = typeof value === 'function' ? value(previousValue) : value;

            if (JSON.stringify(previousValue) !== JSON.stringify(newValue)) {
                return newValue;
            }

            return previousValue;
        });
    }, []);

    useEffect(() => {
        setCfraDataLocalStateOptimized(cfraDataLocalInputProp);
    }, [setCfraDataLocalStateOptimized, cfraDataLocalInputProp]);

    const setCfraDataLocalState: SetCfraDataLocal = useCallback(
        (value, shouldPropagate, lockInsidePage = true) => {
            if (
                (shouldPropagate &&
                    !shouldPropagate?.({
                        cfraDataLocalState,
                        cfraDataLocalInputProp,
                        cfraDataLocalFromContext,
                    })) ||
                !setCfraDataLocalFromContextRef?.current
            ) {
                return setCfraDataLocalStateOptimized(value);
            }

            if (isPageLevelContext && lockInsidePage) {
                return setCfraDataLocalStateOptimized(value);
            }

            return setCfraDataLocalFromContextRef?.current?.(value, shouldPropagate, lockInsidePage);
        },
        [
            setCfraDataLocalFromContextRef,
            setCfraDataLocalStateOptimized,
            cfraDataLocalState,
            cfraDataLocalInputProp,
            cfraDataLocalFromContext,
            isPageLevelContext,
        ],
    );

    const setCfraDataLocalStateRef = useRef(setCfraDataLocalState);

    useEffect(() => {
        setCfraDataLocalStateRef.current = setCfraDataLocalState;
    }, [setCfraDataLocalState]);

    const contextValue: AnalyticsDataContextProps = useMemo(() => {
        return {
            cfraDataLocal: {
                actionData: {
                    ...cfraDataLocalFromContext?.actionData,
                    ...cfraDataLocalState?.actionData,
                },
            },
            setCfraDataLocalRef: setCfraDataLocalStateRef,
        };
    }, [cfraDataLocalFromContext, cfraDataLocalState, setCfraDataLocalStateRef]);

    return <AnalyticsDataContext.Provider value={contextValue}>{memorizedChildren}</AnalyticsDataContext.Provider>;
}
