import FormVirtualizeAutocomplete from '@cfra-nextgen-frontend/shared/src/components/Form/FormVirtualizeAutocomplete';
import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
import { StyledFormLabel } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/StyledFormLabel';
import { getHookFormValidationRules } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/utils';
import { Components, Item } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, Chip } from '@mui/material';
import _ from 'lodash';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { combineIntoFormElementName, getFilterLabel, stackLabelWithContainerProps } from './shared';
import { FilterProps } from './types';

type ScreenerFormVirtualizeAutocompleteOutsideChipsProps = {
    defaultInputLabel?: string;
} & FilterProps;

const autocompleteSxProps = { minWidth: '275px', maxWidth: '275px', maxHeight: '30px' };
const childrenContainerStyles = { display: 'block' };
const renderTags = () => null;
const formHelperTextProps = {
    style: {
        fontSize: '10px',
    },
};

const getExternalChips =
    (title: string, doNotShowDefaultValues?: boolean) =>
    ({ value, onChange, defaultValues }: { value: Array<Item>; onChange: (value: Array<Item>) => void, defaultValues: Array<any> | null }) => {
        //don't set the chips if the value is a placeholder [happens when using presets]
        if (!Array.isArray(value)) return <Box></Box>
       
        const onDelete = (key: number) => () => onChange(value.filter((item) => item.key !== key));
        return (
            <Box sx={{ width: '100%', paddingTop: '40px' }}>
                {value.length > 0 ? (
                    <StyledFormLabel sx={{ lineHeight: 1.2, paddingTop: '10px', paddingBottom: '10px' }}>
                        {title}
                    </StyledFormLabel>
                ) : null}
                {value
                    .filter(({ key }: Item) => doNotShowDefaultValues && defaultValues?.length ? defaultValues.findIndex(v => v.key === key) === -1 : true)
                    .sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
                    .map(({ key, value }: Item) => (
                        <Chip sx={{ margin: '5px' }} key={key} label={value} onDelete={onDelete(key)} />
                    ))}
            </Box>
        );
    };

export const ScreenerFormVirtualizeAutocompleteOutsideChips: React.FC<
    ScreenerFormVirtualizeAutocompleteOutsideChipsProps
> = ({
    control,
    filtersData,
    filterMetadataKey,
    layoutProps,
    submitHandler,
    useFormLabelWithContainer,
    hide,
    parentSectionKey,
    onChangeClearHandler,
    emitControlValues,
    emitDefaultValues,
    disableDefaultValues
}) => {
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const filterSectionMetadata = filterMetadata.sections[parentSectionKey];
    const label = getFilterLabel(filterMetadata, parentSectionKey);
    const ExternalChips = useMemo(() => getExternalChips(filterMetadata.sub_label || '', disableDefaultValues), [filterMetadata.sub_label]);
    const validationRules = getHookFormValidationRules(filtersData, filterMetadataKey, parentSectionKey);
    const defaultValues = filterMetadata.default_values;

    useEffect(() => {
        if (emitDefaultValues && defaultValues && filtersData.data[filterMetadataKey].items) {
            const defaultItems = filtersData.data[filterMetadataKey].items.filter((p) => defaultValues.includes(p.key));
            emitDefaultValues({
                [combineIntoFormElementName({
                    componentName: Components.AutocompleteOutsideChips,
                    filterMetadataKey,
                })]: defaultItems,
            });
        }
    }, [defaultValues, emitDefaultValues, filterMetadataKey, filtersData.data]);

    const processSubmission = (data?: Array<any>) => {
        const addition = _.difference(
            data?.map((p) => p.key),
            defaultValues || [],
        ).map((p) => {
            return { key: p };
        });
        const subtraction = _.filter(
            defaultValues,
            (p) => data?.map((p) => p.key).findIndex((q) => q === p) === -1,
        ).map((p) => {
            return { key: p };
        });
        emitControlValues({
            [combineIntoFormElementName({
                componentName: Components.AutocompleteOutsideChips,
                filterMetadataKey,
            })]: {
                [RequestTypes.POST]: addition,
                [RequestTypes.DELETE]: subtraction,
            },
        });
        submitHandler?.();
    };

    const filter = (
        <FormVirtualizeAutocomplete
            showSelectionsInLabel
            alwaysShowPlaceholder
            enableCount={false}
            helperText={filterMetadata.caption}
            formHelperTextProps={formHelperTextProps}
            ExternalChips={ExternalChips}
            renderTags={renderTags}
            autocompleteSxProps={autocompleteSxProps}
            label={''}
            control={control}
            name={combineIntoFormElementName({
                componentName: Components.AutocompleteOutsideChips,
                filterMetadataKey,
            })}
            options={filtersData.data[filterMetadataKey].items}
            placeholder={filterMetadata.placeholder}
            defaultValues={defaultValues}
            submitHandler={processSubmission}
            onChangeClearHandler={onChangeClearHandler}
            validationRules={validationRules}
            disableDefaultValues={disableDefaultValues}
            search_fields={filterMetadata.search_fields || []}
        />
    );

    if (useFormLabelWithContainer === false) {
        return filter;
    }

    return (
        <FormLabelWithContainer
            label={label}
            layoutProps={layoutProps}
            hide={hide}
            wrapperStyles={filterSectionMetadata.wrapper_styles}
            childrenContainerStyles={childrenContainerStyles}
            {...(filterSectionMetadata?.stack_label_and_filter && stackLabelWithContainerProps)}>
            {filter}
        </FormLabelWithContainer>
    );
};
