import { Layout } from '@cfra-nextgen-frontend/shared';
import { ResultRow } from '@cfra-nextgen-frontend/shared/src/components/Dropdown/Dropdown';
import { inputFontStyle } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/StyledFormLabel';
import { Item } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Checkbox, Chip, FormHelperTextProps, SxProps, Theme, ThemeProvider, createTheme } from '@mui/material';
import Autocomplete, { AutocompleteRenderGetTagProps, autocompleteClasses } from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ListChildComponentProps, VariableSizeList as VariableSizeListOriginal } from 'react-window';
import { CommonFormComponentProps } from './types/form';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { useRef } from 'react';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';

export const LISTBOX_PADDING = 8; // px

// fix for annoying error - TS2786: 'VariableSizeList' cannot be used as a JSX component.
// this is caused by changes in the packages not controlled by us
const VariableSizeList = VariableSizeListOriginal as any;

const ResultItem = styled(Layout.Grid)(({ theme }) => ({
    lineHeight: '20px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
}));

const getRenderRow = (enableCount: boolean = true) =>
    function (props: ListChildComponentProps) {
        const { data, index, style } = props;
        const dataSet = data[index];
        const inlineStyle = {
            ...style,
            top: (style.top as number) + LISTBOX_PADDING,
        };

        const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
        const checkedIcon = <CheckBoxIcon fontSize='small' />;
        const count = (dataSet[1] as Item).count;
        const { key, ...rest } = dataSet[0];

        return (
            <li {...rest} key={key} style={inlineStyle}>
                <ResultRow container alignItems='left'>
                    <ResultItem item>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    width: '23px',
                                    height: '23px',
                                },
                                width: '48px',
                                height: '48px',
                            }}
                            checked={dataSet[0]['aria-selected']}
                        />
                    </ResultItem>
                    <ResultItem
                        item
                        xs={enableCount ? 8 : 10}
                        sx={{
                            paddingRight: '24px',
                        }}>
                        <Grid
                            sx={{
                                display: '-webkit-box',
                                WebkitLineClamp: '2',
                                wordWrap: 'break-word',
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                ...inputFontStyle,
                            }}>
                            {(dataSet[1] as Item).value}
                        </Grid>
                    </ResultItem>
                    {enableCount && count ? (
                        <ResultItem
                            item
                            sx={{ justifyContent: 'end', flexGrow: 1, paddingRight: '12px', ...inputFontStyle }}>
                            {(dataSet[1] as Item).count}
                        </ResultItem>
                    ) : null}
                </ResultRow>
            </li>
        );
    };

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
    const ref = React.useRef<typeof VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const getListboxComponent = (enableCount?: boolean) =>
    React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
        const { children, ...other } = props;

        const itemData: Array<React.ReactNode> = [];

        (children as React.ReactNode[]).forEach((item) => {
            itemData.push(item);
            if (!item) {
                return;
            }
            itemData.push(...((item as React.ReactNode & { children: Array<React.ReactNode> }).children || []));
        });

        const itemCount = itemData.length;
        const itemSize = 48;
        const gridRef = useResetCache(itemCount);

        const height = (itemCount > 10 ? 10 : itemCount) * itemSize + 2 * LISTBOX_PADDING;

        const renderRow = useMemo(() => getRenderRow(enableCount), []);

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <VariableSizeList
                        itemData={itemData}
                        height={height}
                        width='100%'
                        ref={gridRef}
                        outerElementType={OuterElementType}
                        innerElementType='ul'
                        itemSize={() => itemSize}
                        overscanCount={5}
                        itemCount={itemCount}>
                        {renderRow}
                    </VariableSizeList>
                </OuterElementContext.Provider>
            </div>
        );
    });

export const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

// Default Theme
const defaultTheme = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: '#3078B5',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&:not(.Mui-focused)': {
                        top: '-11.5px', // center inactive label
                    },
                    ...inputFontStyle,
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    textTransform: 'capitalize',
                    maxWidth: '212px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    padding: '3.5px !important',
                    paddingRight: '36px !important',
                    height: '100%',
                },
                input: {
                    padding: '0px !important',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                popper: {
                    width: '300px !important',
                },
                input: {
                    '&::placeholder': {
                        ...inputFontStyle,
                    },
                    flexBasis: '100%',
                    minWidth: '30px !important',
                },
                paper: {
                    filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
                    boxShadow: 'none',
                },
                listbox: {
                    padding: `${LISTBOX_PADDING}px 0px ${LISTBOX_PADDING}px`,
                    '::-webkit-scrollbar': {
                        width: '20px',
                    },
                    '::-webkit-scrollbar-thumb': {
                        backgroundColor: '#d1d8e8',
                    },
                    '::-webkit-scrollbar-track-piece': {
                        backgroundColor: '#f2f5fc',
                    },
                },
                option: {
                    padding: '0px 12px 0px 0px !important',
                    ':hover': {
                        backgroundColor: '#f7f6f7',
                    },
                    textTransform: 'capitalize',
                },
                popupIndicator: {
                    width: '24px',
                    height: '24px',
                },
            },
        },
    },
});

function getRenderTags(props: VirtualizeFormAutocompleteProps) {
    return (
        props.renderTags ||
        ((value, getTagProps) => {
            if (value.length === 0) return null;

            if (value.length === 1) {
                const { key, ...restTagProps } = getTagProps({ index: 0 });

                return (
                    <Chip
                        key={key}
                        label={value[0].value}
                        {...restTagProps}
                        onDelete={(event: any) => {
                            restTagProps.onDelete(event);
                            props.submitHandler?.();
                        }}
                        sx={props.ignoreFocus ? { display: 'none' } : { textTransform: 'none' }}
                    />
                );
            }

            return (
                <div
                    style={{
                        color: '#007bb8',
                        fontSize: '15px',
                        fontFamily: fontFamilies.GraphikRegular,
                        margin: props.highlightOnSelection ? '12px 12px' : '0px 0px',
                        display: props.ignoreFocus ? 'none' : 'block',
                    }}>
                    {value.length} Selections
                </div>
            );
        })
    );
}

type VirtualizeFormAutocompleteProps = {
    options: Array<Item>;
    placeholder?: string;
    defaultValues?: Array<number> | null;
    label: string;
    defaultInputLabel?: string;
    renderTags?: (value: Array<Item>, getTagProps: AutocompleteRenderGetTagProps) => React.ReactNode;
    autocompleteSxProps?: SxProps;
    ExternalChips?: React.FC<{
        value: Array<Item>;
        onChange: (value: Array<Item>) => void;
        defaultValues: Array<any> | null;
    }>;
    formHelperTextProps?: Partial<FormHelperTextProps>;
    helperText?: string;
    enableCount?: boolean;
    showSelectionsInLabel?: boolean;
    alwaysShowPlaceholder?: boolean;
    validationRules?: any;
    theme?: Theme;
    ignoreFocus?: boolean;
    disableDefaultValues?: boolean;
    doNotSetDefaultValue?: boolean;
    highlightOnSelection?: boolean;
    alwaysShowLabel?: boolean;
    search_fields?: Array<string>;
} & CommonFormComponentProps;

const highlightTheme = {
    '& .MuiOutlinedInput-root': {
        '& fieldset': { borderColor: '#1976d2', borderWidth: '2px' },
    },
    '& .MuiInputLabel-root': {
        color: '#1976d2',
    },
    width: 'fit-content',
};

export default function FormVirtualizeAutocomplete(props: VirtualizeFormAutocompleteProps) {
    const theme = props.theme || defaultTheme;
    const defaultValues =
        props?.getValues?.(props.name) || props.defaultValues
            ? props.options.filter((option) => props.defaultValues?.includes(option.key))
            : [];

    const unfocusedTextInputLabel = props.defaultInputLabel || 'Any';
    const [textInputLabel, setTextInputLabel] = useState(unfocusedTextInputLabel);
    const ListboxComponent = useMemo(() => getListboxComponent(props.enableCount), [props.enableCount]);

    const getFilterOptions = function (search_fields: Array<string> = []) {
        if (search_fields.length === 0) return undefined;
        return (options: Array<any>, { inputValue }: { inputValue: string }) => {
            if (inputValue === '') return options;
            return options.filter((option) =>
                search_fields.some((field) => option[field].toLowerCase().includes(inputValue.toLowerCase())),
            );
        };
    };

    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);

    return (
        <>
            <AnalyticsDataPicker ref={analyticsDataPickerRef} />
            <Controller
                name={props.name}
                control={props.control}
                defaultValue={props.doNotSetDefaultValue ? undefined : defaultValues}
                rules={props.validationRules}
                render={({ field, fieldState: { error } }) => {
                    const completeOnChange = (data: Array<any>) => {
                        field.onChange(data);
                        props.onChangeClearHandler?.(field.name);
                        props.submitHandler?.(data);
                    };

                    return (
                        <ThemeProvider theme={theme}>
                            <Autocomplete
                                {...field}
                                popupIcon={<KeyboardArrowDownIcon />}
                                isOptionEqualToValue={(option, value) => option.key === value.key}
                                options={props.options}
                                disabled={props.options.length === 0}
                                getOptionLabel={(option) => String(option.value)}
                                slotProps={{
                                    popper: {
                                        sx: { zIndex: 3000 },
                                    },
                                }}
                                filterOptions={getFilterOptions(props.search_fields)}
                                renderTags={getRenderTags(props)}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            label={(() => {
                                                let valueLength =
                                                    (field.value?.length || 0) -
                                                    (props.disableDefaultValues ? defaultValues?.length || 0 : 0);

                                                //if a user types selection, then clears it and mistakenly presses backspace, "placeholder-1 selections" error is displayed.
                                                //and a default disabled selection becomes unchecked.
                                                if (valueLength < 0) {
                                                    if (props.disableDefaultValues && defaultValues.length > 0) {
                                                        completeOnChange(defaultValues);
                                                    }
                                                    valueLength = 0;
                                                }

                                                if (props.doNotSetDefaultValue && field.value === undefined) {
                                                    return props.label;
                                                }

                                                if (props.alwaysShowLabel) {
                                                    return textInputLabel || '';
                                                }

                                                return props.showSelectionsInLabel
                                                    ? `${valueLength || 'No'} selection${valueLength === 1 ? '' : 's'}`
                                                    : (valueLength === 0 && textInputLabel) || '';
                                            })()}
                                            placeholder={
                                                props.alwaysShowPlaceholder
                                                    ? props.placeholder
                                                    : (field.value.length === 0 &&
                                                          !props.ignoreFocus &&
                                                          props.placeholder) ||
                                                      ''
                                            }
                                            onFocus={() => {
                                                setTextInputLabel(props.label);
                                            }}
                                            onBlur={() => {
                                                setTextInputLabel(unfocusedTextInputLabel);
                                            }}
                                            error={Boolean(error?.message)}
                                            helperText={
                                                Boolean(error?.message) ? error?.message : props?.helperText || ''
                                            }
                                            FormHelperTextProps={props.formHelperTextProps}
                                        />
                                    );
                                }}
                                onChange={(event, data, reason, details) => {
                                    analyticsDataPickerRef.current?.registerAction({
                                        action: joinWithDelimiter({
                                            values: ['autocomplete', props.defaultInputLabel || props.label, reason, (details?.option as any)?.value, (details?.option as any)?.key],
                                        }),
                                    });

                                    completeOnChange(data);
                                }}
                                value={
                                    Array.isArray(field.value)
                                        ? field.value
                                        : props.doNotSetDefaultValue
                                        ? undefined
                                        : []
                                } //if field.value is a placeholder value [happens when using presets], then replace it. Once the placehold value is replaced, it will be set
                                multiple
                                disableCloseOnSelect
                                disableListWrap
                                PopperComponent={StyledPopper}
                                sx={{
                                    ...(props.autocompleteSxProps || { minWidth: '125px', maxWidth: '275px' }),
                                    ...(props.highlightOnSelection && field.value.length > 0 ? highlightTheme : {}),
                                }}
                                ListboxComponent={ListboxComponent}
                                renderOption={(props, option, state) => [props, option, state.index] as React.ReactNode}
                                getOptionDisabled={({ key, value }) =>
                                    props.disableDefaultValues
                                        ? defaultValues.findIndex((v) => v.key === key) > -1
                                        : false
                                }
                            />
                            {props.ExternalChips ? (
                                <props.ExternalChips
                                    value={field.value}
                                    onChange={completeOnChange}
                                    defaultValues={defaultValues}
                                />
                            ) : null}
                        </ThemeProvider>
                    );
                }}
            />
        </>
    );
}
