import { DefaultCFRASnack, RoundedTextButton } from '@cfra-nextgen-frontend/shared';
import { roundedTextButtonThemeV2BorderRadius4, roundedTextRemoveButtonTheme } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { CancelWithConfirmation } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/CancelWithConfirmation';
import { OperateEntityWithConfirmation } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/OperateEntityWithConfirmation';
import '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/etfScreenerFilterSearch/FiltersForm.scss';
import { RhFormData } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { SnackMessageForm } from '@cfra-nextgen-frontend/shared/src/components/Snack/SnackMessageForm';
import { OperationTypes, RequestTypes, serverErrorToBeautifiedMessage } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useMemo } from 'react';
import { FormState } from 'react-hook-form';
import { AgGridSelectedRowsContext } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import { FiltersModalOpenButton } from '../filtersModal/FiltersModalOpenButton';


export function getEditButtonVariant2({
    addButtontitle,
    defaultConfirmTitle = 'Are you sure you want to update?',
    buttonCaptions = ['Add', 'Delete'],
    position = 'right',
}: {
    addButtontitle: string;
    defaultConfirmTitle?: string;
    buttonCaptions?: Array<string>;
    position?: 'left' | 'right';
}) {
    return function EditButtonsVariant2({
        analyticsCardName,
        edit,
        editButtonText,
        filtersData,
        formData,
        formState,
        modifyRequestBody,
        onEditChange,
        onDeleteClicked,
        onUpdate,
        requestPath,
        updateConfirmTitle,
        sectionKey,
        requestDefaultValue,
        cardName,
        confirmationModalContent,
        formDataState,
        operationType,
        modifyDeleteRequestFn,
    }: {
        analyticsCardName: string;
        edit: boolean;
        editButtonText: string;
        filtersData: FiltersData;
        formData?: RhFormData;
        formState: FormState<any>;
        modifyRequestBody?: (requestBody: { [key: string]: any }) => { [key: string]: any };
        onEditChange: (v: boolean) => void;
        onUpdate: (data: any) => void;
        requestPath: string;
        updateConfirmTitle?: string;
        sectionKey?: string;
        requestDefaultValue?: Record<string, any>;
        cardName?: string;
        onDeleteClicked?: (data: any) => void;
        confirmationModalContent?: React.ReactNode;
        formDataState?: Record<string, any>;
        operationType?: OperationTypes;
        modifyDeleteRequestFn: (requestPath: string, extraParams: any) => any;
    }) {
        const { enqueueSnackbar } = useSnackbar();
        const ShowSnack = DefaultCFRASnack(enqueueSnackbar);
        const { selectedRowIds, clearRowsSelections } = useContext(AgGridSelectedRowsContext);

        const enableSubmitButton = useMemo(() => {
            let enable = false;
            if (formDataState && Object.keys(formDataState).length) {
                enable = Object.keys(formDataState).some(
                    (val) =>
                        formDataState[val][RequestTypes.DELETE]?.length ||
                        formDataState[val][RequestTypes.POST]?.length,
                );
            }
            if (!enable) {
                enable = Boolean(formState.isDirty && formState.isValid);
            }
            return enable;
        }, [formState.isDirty, formState.isValid, formDataState]);

        const handleOnEditClick = useCallback(() => {
            onEditChange(true);
            globalThis.analytics?.registerAction?.({
                action: `click on ${editButtonText}`,
                cardName: analyticsCardName,
            });
        }, [analyticsCardName, onEditChange, editButtonText]);

        const editButton = useMemo(() => {
            return (
                <RoundedTextButton
                    key={`${requestPath}EditBtn`}
                    theme={roundedTextButtonThemeV2BorderRadius4}
                    buttonText={editButtonText}
                    onClickCallback={handleOnEditClick}
                />
            );
        }, [editButtonText, requestPath, handleOnEditClick]);

        const onOperationFailure = useCallback(
            (data: any) => {
                const beautifiedMessage =
                    serverErrorToBeautifiedMessage[data?.message] ||
                    `An error occurred while editing the item. ${data?.message}`;
                if (data?.isErroredResponse) {
                    ShowSnack(
                        SnackMessageForm({
                            message: beautifiedMessage,
                        }),
                    );
                }
            },
            [ShowSnack],
        );

        const operateEntityWithConfirmation = useMemo(() => {
            return (
                <OperateEntityWithConfirmation
                    requestType={RequestTypes.PUT}
                    requestPath={requestPath}
                    confirmModalText={updateConfirmTitle || defaultConfirmTitle}
                    enableSubmitButton={Boolean(enableSubmitButton)}
                    analyticsCardName={`${analyticsCardName}SaveConfirm`}
                    dirtyFields={formState.dirtyFields}
                    formData={formData}
                    filtersData={filtersData}
                    onOperationSuccess={onUpdate}
                    onOperationFailure={onOperationFailure}
                    modifyRequestBody={modifyRequestBody}
                    sectionKey={sectionKey}
                    requestDefaultValue={requestDefaultValue}
                    confirmationModalContent={confirmationModalContent}
                    formDataState={formDataState}
                    operationType={operationType}
                    modifyDeleteRequestFn={modifyDeleteRequestFn}
                />
            );
        }, [
            analyticsCardName,
            enableSubmitButton,
            filtersData,
            formData,
            modifyRequestBody,
            requestPath,
            updateConfirmTitle,
            formState.dirtyFields,
            onUpdate,
            sectionKey,
            requestDefaultValue,
            onOperationFailure,
        ]);

        const cancelWithConfirmation = useMemo(() => {
            return (
                <CancelWithConfirmation
                    confirmModalText='Your changes will not be saved. Proceed?'
                    isDirty={enableSubmitButton}
                    analyticsCardName={`${analyticsCardName}CancelConfirm`}
                    onCancel={() => {
                        clearRowsSelections();
                        onEditChange(false);
                    }}
                    preventChangingRoute
                />
            );
        }, [analyticsCardName, onEditChange, enableSubmitButton]);

        const addButton = useMemo(() => {
            return (
                <FiltersModalOpenButton
                    cardName={cardName || buttonCaptions[0]}
                    key={`topLeftActionPanelItems - ${addButtontitle}`}
                    manageFiltersButtonText={`${buttonCaptions[0]} ${cardName}`}
                    multipleModalsContextKey={addButtontitle}
                />
            );
        }, [cardName]);

        const deleteButton = useMemo(() => {
            return (
                <RoundedTextButton
                    key={`${requestPath}DeleteBtn`}
                    theme={roundedTextRemoveButtonTheme}
                    buttonText={`${buttonCaptions[1]} ${cardName}`}
                    onClickCallback={() => onDeleteClicked?.(selectedRowIds)}
                    disabled={selectedRowIds?.length === 0}
                />
            );
        }, [cardName, filtersData, onEditChange, requestPath, selectedRowIds]);

        return (
            <>
                {!edit && editButton}
                {edit && (
                    <Box sx={{ display: 'flex', justifyContent: 'left', gap: '21px', p: 0 }}>
                        {position==='left' && deleteButton}
                        {position==='left' && addButton}
                        {cancelWithConfirmation}
                        {position==='right' && deleteButton}
                        {position==='right' && addButton}
                        {operateEntityWithConfirmation}
                    </Box>
                )}
            </>
        );
    };
}